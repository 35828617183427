import DesktopHome from "../views/DesktopHome";
import TabletHome from "../views/TabletHome";
import MobileHome from "../views/MobileHome";
import React, {useState} from "react";

function Home() {

    const [home, setHome] = useState(<DesktopHome />);

    window.onload = () => {
        if(window.innerWidth > 1024){
            setHome(<DesktopHome />)
        }
        if(window.innerWidth <= 1024){
            setHome(<TabletHome />)
        }
        if(window.innerWidth < 550){
            setHome(<MobileHome />)
        }
    }

    return(
        <div id="Home-window">
            {home}
        </div>
    )    
        
    
}

export default Home;
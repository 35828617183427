import Logo from "../images/rjaLogo.png";
import Code from "../images/jedi-code.png";
import Donate from "../images/paypalDonate.png";

const DesktopHeader = () => {
    return(
        <header className="Header">
            <img className="RJA-logo" src={Logo} alt="RJA Logo" />
            <img className="Jedi-code" src={Code} alt="Jedi Code in Old Tongue" />
            <a className="Donate" href="https://www.paypal.com/donate/?business=AFC3KZFR9ANH8&no_recurring=0&currency_code=USD"><img className="PaypalDonate" src={Donate} alt="Donate" /></a>
        </header>
    )
}

export default DesktopHeader;
import Logo from "../images/rjaLogo.png";
import Donate from "../images/paypalDonate.png";
import Reddit from "../images/reddit.png";
import Discord from "../images/discord.png";

const MobileHeader = () => {
    return(
        <header className="MobileHeader">
            <img className="RJA-logo" style={{width:"30%", padding:"none", marginTop:"2%", marginLeft:"0", marginBottom:"2%"}} src={Logo} alt="RJA Logo" />
            <div className="Header-clickables">
                <a href="https://www.reddit.com/r/RealJediArts/"><img src={Reddit} alt="Reddit" style={{height:"24px", padding:"5px"}} /></a>
                <a href="https://discord.gg/Br8KN3uZRS"><img src={Discord} alt="Discord" style={{height:"24px", padding:"5px"}} /></a>
                <a href="https://www.paypal.com/donate/?business=AFC3KZFR9ANH8&no_recurring=0&currency_code=USD"><img style={{height:"24px", padding:"5px"}} src={Donate} alt="Donate" /></a>
            </div>
        </header>
    )
}

export default MobileHeader;
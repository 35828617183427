import Header from "../components/DesktopHeader";
import Reddit from "../images/reddit.png";
import RedditImg from "../images/redditImg.png";
import Discord from "../images/discord.png";
import DiscordImg from "../images/discordImg.png";
import BookAdImg from "../images/rja-book-preorder-ad.jpg";
import Footer from "../components/Footer";

function DesktopHome(){
    return(
        <div className="DesktopHome">
            <Header />
            <div className="Main">
                <div className="Left-main">
                    <h1 className="Site-title">Real Jedi Arts</h1>
                    <div className="Intro-top">
                        
                        <p className="Intro-top-txt" style={{marginTop:"0%"}}>
                        <b className="Big-letter">A</b>t Real Jedi Arts, we teach and promote the Jedi Way as a meaningful philosophy for living one’s life. 
                        To be a real Jedi means to act like a Jedi, live like a Jedi, and serve a higher calling - like a Jedi. 
                        One can play pretend with toys and costumes, or one can work to transform into people befitting the Jedi title. 
                        At RJA, we do things the latter way. </p>
                    </div>
                    
                    <p className="Intro-other-txt">
                    Since the mid-90s, groups of like-minded enthusiasts have been meeting on forums, servers,and chat rooms to discuss the Jedi Way 
                    and use it to better their own lives and the lives of others. At its core, the Jedi Way has nothing to do with lightsabers and magic 
                    powers, while it has everything to do with virtue, morality, and serving the greater good.  
                    </p>
                    <p className="Intro-other-txt">
                    Jedi Realists are those who use the philosophy of Star Wars to become Jedi of the real world. This comes with intensive training, taking 
                    responsibility for making the world a better place, and living in alignment with Jedi ideals like courage, wisdom, discipline, and compassion. 
                    This is no game of make-believe that ends when the robe hood comes down. This is a daily commitment - no different from a commitment to be a Buddhist 
                    or a Stoic.
                    </p>
                    <p className="Intro-other-txt">
                    Real Jedi Arts is a group of like-minded Realists with an interest in spreading knowledge and creating a community of Jedi that can come together and work 
                    on issues of local and global importance. In a world where the self-interested and reckless refuse to take care of the innocent, disadvantaged, and disenfranchised; 
                    who refuse to look after the planet and mitigate the suffering of other lifeforms… there is room for true Jedi of the real world to stand up and make a difference.  
                    </p>
                    <p className="Intro-other-txt">
                    Real Jedi Arts is dedicated to the empowerment of others into Jedi that can take action in their own parts of the world. We post articles to Reddit, discuss issues on Discord, 
                    and the few able and willing to train into Jedi are provided the best training we can offer. <br></br>
                    </p>
                    <b style={{textAlign:"center", marginLeft:"7%"}}>You can find Real Jedi Arts on Reddit at the subreddit <a href="https://www.reddit.com/r/RealJediArts/" style={{color:"#C6A336"}}>r/RealJediArts</a>, or on Discord at <a style={{color:"#C6A336"}} href="https://discord.gg/Br8KN3uZRS">Real Jedi Arts</a>. </b>
                    
                </div>
                <aside className="Right-main">
                    <a href="https://www.amazon.com/dp/B0DMFPZLRX" style={{textDecoration:"none"}} className="Book-section">
                        <img className="Book-ad-img" src={BookAdImg} alt="Live Like a Jedi Ebook on Amazon" />
                    </a>
                    <a href="https://www.reddit.com/r/RealJediArts/" style={{textDecoration:"none"}} className="Reddit-section"><div className="Reddit-div">
                        <div className="Reddit-join">
                            <img className="Icon" src={Reddit} alt="Reddit" />
                            <p className="Reddit-txt">Join Us On Reddit</p>
                        </div>
                        <img className="Reddit-img" src={RedditImg} alt="Real Jedi Arts on Reddit" />
                    </div></a>
                    
                    <a href="https://discord.gg/Br8KN3uZRS" style={{textDecoration:"none"}} className="Discord-section"><div className="Discord-div">
                        <div className="Discord-join">
                            <img className="Icon" src={Discord} alt="Discord" style={{height:"24px", width:"24px"}} />
                            <p className="Discord-txt">Join Us On Discord</p>
                        </div>
                        <img className="Discord-img" src={DiscordImg} alt="Real Jedi Arts on Discord" />
                    </div></a>
                </aside>
            </div>
            <Footer />
        </div>
    )
}

export default DesktopHome;
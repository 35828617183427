import Reddit from "../images/reddit.png";
import Discord from "../images/discord.png";
import Logo from "../images/rjaLogo.png";
import Code from "../images/jedi-code.png";
import Donate from "../images/paypalDonate.png";

const TabletHeader = () => {
    return(
        <header className="Header">
            <img className="RJA-logo" src={Logo} alt="RJA Logo" />
            <img className="Jedi-code" src={Code} alt="Jedi Code in Old Tongue" />
            <div className="Clickables">
                <a className="Donate" href="https://www.paypal.com/donate/?business=AFC3KZFR9ANH8&no_recurring=0&currency_code=USD"><img className="PaypalDonate" src={Donate} alt="Donate" /></a>
                <div className="Socials">
                    <a href="https://www.reddit.com/r/RealJediArts/" className="Clickable-socials"><img src={Reddit} alt="Reddit" className="Icon" /></a>
                    <a href="https://discord.gg/Br8KN3uZRS" className="Clickable-socials"><img src={Discord} alt="Discord" className="Icon" /></a>
                </div>
            </div>
            
        </header>
    )
}

export default TabletHeader;
import Code from "../images/jedi-code.png";

const MobileFooter = () => {
    return(
        <footer className="Footer" style={{height:"20vh", width:"100%"}}>
            <img className="Jedi-code" style={{width:"60%"}} src={Code} alt="Jedi Code in Old Tongue" />
        </footer>
    )
}

export default MobileFooter;